import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import NavBar from './components/NavBar'
import Home from './pages/home'
import About from './pages/about'
import Products from './pages/products'
import Clients from './pages/clients'
import Contact from './pages/contact'
import theme from './theme'
import { ThemeProvider } from '@mui/material'

const router = createBrowserRouter([
  {
    path: '/',
    element: <NavBar />,
    errorElement: <div>Error Page</div>,
    children: [
      { index: true, element: <Home /> },
      {
        path: 'about',
        element: <About />,
      },
      {
        path: 'products',
        element: <Products />,
      },
      {
        path: 'clients',
        element: <Clients />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
    ],
  },
])
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
