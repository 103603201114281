import {
  Box,
  Button,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import Carousel from 'react-material-ui-carousel'
function Item(props) {
  return (
    <img
      src={props.item.logo}
      alt={props.item.name}
      width="100px"
      loading="lazy"
    />
  )
}

export default function Home() {
  var data = [
    {
      logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/MasterCard_Logo.svg/2560px-MasterCard_Logo.svg.png',
      name: 'MasterCard Foundation',
    },
    {
      logo: 'https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/fbck2kpa2mx6kqkc0kos',
      name: 'Orbit Health',
    },
    {
      logo: 'https://ethiojobs.b-cdn.net/wp-content/uploads/2021/03/Zewditu-Metasebia-Hospital.jpg',
      name: 'Zewditu Memorial Hospital',
    },
  ]

  const sliderItems = data.length > 4 ? 4 : data.length
  const items = []

  for (let i = 0; i < data.length; i += sliderItems) {
    if (i % sliderItems === 0) {
      items.push(
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {data.slice(i, i + sliderItems).map((da, index) => {
            return <Item key={index.toString()} item={da} />
          })}
        </Box>
      )
    }
  }

  let cardData = [
    {
      image:
        'https://www.timechecksoftware.com/wp-content/uploads/2019/02/Automatic-Scheduling.jpg',
      title: 'Scheduling & Attendance Report',
      description:
        'Customized working schedule for employees and attendance report based on the provided schedule',
    },
    {
      image:
        'https://www.gierschgroup.com/Content/files/balance-business-calculat.jpg',

      title: 'Financial Reports',
      description:
        'Integrates financial reports driven from attendance reports to make a timely payment for employees',
    },
    {
      image:
        'https://rsetasc.pnwboces.org/wp-content/uploads/2019/05/data-charts-with-two-small-people-SML.jpg',

      title: 'Data driven decision making',
      description:
        'Stores human resource data and provides a customized analysis to make a data driven decisions regarding human resource',
    },
  ]

  const teamMember = [
    {
      image: './kaleab.jpg',

      professional: 'MD, Co-founder',
      name: 'Dr. Kaleab Girma',
    },
    {
      image: './medhin.jpg',

      professional: 'MD, Co-founder',
      name: 'Dr. Medhin Getaneh',
    },
    {
      image:
        'https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png',

      professional: 'Senior Computer and software engineer, Co-founder',
      name: 'Mikias Abrham',
    },
  ]

  return (
    <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }} gap={10}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={4} sm={4} md={6}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box>
              <img
                style={{
                  opacity: 0.1,
                  position: 'fixed',
                  left: '10%',
                  top: '15%',
                }}
                src="./medket_logo.png"
                alt="medket logo"
              />
              <Typography fontWeight="bold" variant="h4">
                Efficient Resource Management.
              </Typography>
              <p
                style={{
                  maxWidth: '800px',
                }}
              >
                We offer innovative and timely solutions to enable you to
                enhance your competitiveness and efficiency in the present
                digital healthcare era.
              </p>
              <Button
                sx={{ borderRadius: 10, fontWeight: 'bold', mt: '5px' }}
                variant="contained"
              >
                Contact Us
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          <Box maxWidth="800px">
            <CardMedia
              sx={{ p: '10px 10px 0px 10px', borderRadius: 0.5 }}
              component="img"
              height="180"
              image="https://www.nih.gov/sites/default/files/news-events/news-releases/2021/20210309-covid.jpg"
              alt="green iguana"
            />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          style={{
            opacity: 0.1,
            position: 'fixed',
            left: '10%',
          }}
          src="./medket_logo.png"
          alt="medket logo"
        />
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          p={3}
        >
          <Typography fontWeight="bold" variant="h5">
            Who are we ?
          </Typography>
          <Divider
            sx={{
              bgcolor: 'primary.main',
              height: '5px',
              width: '100px',
              mr: '100px',
            }}
          />
        </Box>
        <Typography maxWidth="800px">
          <p id="about">
            Welcome to MedkET Tech Solutions, a health tech company that aims to
            improve healthcare through innovation and technology. Our mission is
            to provide healthcare professionals and institutions with various
            solutions that will help them focus on providing quality care. We
            strongly believe that technology can significantly improve the
            efficiency of healthcare institutions, and our goal is to equip them
            with the appropriate platform.
          </p>
          <p>
            Our flagship product is a customizable electronic human resource
            management system (eHRM), called Efoy HRM, which is an integrated
            upgrade from the current paper-based HR management system. This
            system is designed to streamline HR processes such as scheduling,
            attendance, and payment, making us pioneers in this field in
            Ethiopia. Our solution integrates work schedules with attendance and
            financial reports to provide a comprehensive HR management system.
          </p>
          <p>
            Our co-founders and partners come from diverse backgrounds and have
            deep knowledge of the challenges that healthcare institutions face.
            With their motivation, talent, and experience, we are dedicated to
            providing digital solutions for healthcare institutions and making
            them the best destination for healthcare in Africa.
          </p>
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 2,
          },
        }}
      >
        {cardData.map((item, index) => (
          <Paper elevation={5} key={index}>
            <MediaCard key={index} data={item} />
          </Paper>
        ))}
      </Box>
      <Box>
        <Typography textAlign="center" fontWeight="bold" variant="h5">
          Team Members
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 2,
            },
          }}
        >
          {teamMember.map((item, index) => (
            <Paper elevation={5} key={index}>
              <ImageCard key={index} data={item} />
            </Paper>
          ))}
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} mx="20%"></Box>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Typography fontWeight="bold" variant="h5">
          We&apos;ve worked with
        </Typography>
        <Divider
          sx={{
            bgcolor: 'primary.main',
            height: '5px',
            width: '100px',
            mr: '140px',
          }}
        />
      </Box>
      <Box pb={10}>
        <p id="clients" />
        <Carousel animation="slide" autoPlay={true} cycleNavigation>
          {items}
        </Carousel>
      </Box>
    </Box>
  )
}

function MediaCard(props) {
  return (
    <Box width={300}>
      <CardActionArea>
        <CardMedia
          sx={{ p: '10px 10px 0px 10px', borderRadius: 0.5 }}
          component="img"
          height="180"
          image={props.data.image}
          alt="green iguana"
        />
        <CardContent>
          <Typography fontWeight="bold" variant="subtitle1" textAlign="center">
            {props.data.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.data.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Box>
  )
}

function ImageCard(props) {
  return (
    <Box width={250}>
      <CardActionArea>
        <CardMedia
          sx={{ borderRadius: '50%', p: 2 }}
          component="img"
          height="250"
          image={props.data.image}
          alt="green iguana"
        />

        <CardContent>
          <Typography fontWeight="bold" variant="subtitle1">
            {props.data.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.data.professional}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Box>
  )
}
