import * as React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import { NavLink, Outlet } from 'react-router-dom'
import Footer from './Footer'
import { AppBar } from '@mui/material'

const drawerWidth = 160
const navItems = [
  { linkTo: '.', text: 'Home' },
  { linkTo: '#about', text: 'About' },
  { linkTo: '#products', text: 'Products' },
  { linkTo: '#clients', text: 'Clients' },
  { linkTo: '#contact', text: 'Contact' },
]

function NavBar(props) {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  function TopItem({ linkTo, text }) {
    return (
      <NavLink to={linkTo} style={{ textDecoration: 'none', color: 'black' }}>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'start' }}>
            <ListItemText primary={text} />
          </ListItemButton>
        </ListItem>
      </NavLink>
    )
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <img src="./medket.png" alt="Medket logo" height="80px" loading="lazy" />
      <Divider />
      <List>
        {navItems.map((item) => (
          <TopItem key={item.linkTo} linkTo={item.linkTo} text={item.text} />
        ))}
      </List>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box>
      <CssBaseline />
      <AppBar
        sx={{
          height: '80px',
          bgcolor: 'white',
          border: 'none',
          boxShadow: 'none',
          display: 'block',
        }}
      >
        <IconButton
          color="default"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mx={10}
        >
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <img
              src="./medket.png"
              alt="Medket logo"
              width="120px"
              loading="lazy"
            />
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'flex' } }} gap={5} m={5}>
            {navItems.map((item) => (
              <a
                style={{ textDecoration: 'none', color: 'black' }}
                // style={({ isActive }) =>
                //   isActive
                //     ? { color: '#1C87CA', textDecoration: 'none' }
                //     : { color: 'black', textDecoration: 'none' }
                // }
                key={item.linkTo}
                href={item.linkTo}
              >
                {item.text}
              </a>
            ))}
          </Box>
        </Box>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </AppBar>
      <Box component="main" sx={{ mt: 15, mx: 5 }}>
        <Outlet />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="secondary.main"
        color="white"
        p={10}
      >
        <Footer />
      </Box>
    </Box>
  )
}

NavBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

export default NavBar
