import { LinkedIn } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import Carousel from 'react-material-ui-carousel'
import { Link } from 'react-router-dom'
import ContactForm from './ContactForm'

// function Item(props) {
//   return (
//     <Box>
//       <Typography color="whitesmoke">{props.item.description}</Typography>
//       <Typography fontWeight="bold" variant="body1">
//         -{props.item.from}
//       </Typography>
//     </Box>
//   )
// }

export default function Footer() {
  // const testimonials = [
  //   {
  //     description:
  //       'Et quis ea sit exercitation. Sunt occaecat velit consectetur qui veniam laboris commodo ut in officia mollit. Lorem sunt ad cillum in deserunt quis. Occaecat pariatur in culpa cillum elit velit exercitation enim ipsum in officia eiusmod. Magna velit ullamco enim velit dolore Magna excepteur excepteur laborum quis dolor duis sunt sint qui. Exercitation ex voluptate laboris amet enim ex eiusmod velit ad velit commodo dolore officia. Do officia dolor eu cupidatat esse eiusmod mollit aliquip in laboris proident irure. ',
  //     from: 'Albert Einstein',
  //   },
  //   {
  //     description:
  //       'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ',
  //     from: 'john doe',
  //   },
  // ]
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      <Grid item xs={4} sm={6} md={4}>
        <Box width="100%" display="flex" justifyContent="center">
          <ContactForm />
        </Box>
      </Grid>
      <Grid item xs={4} sm={6} md={4}>
        <Box>
          <Typography fontWeight="bold" variant="h5">
            Testimonials
          </Typography>
          <Carousel>
            ----------------------------
            {/* {testimonials.map((item, i) => (
              <Item key={i} item={item} />
            ))} */}
          </Carousel>
        </Box>
      </Grid>
      <Grid item xs={4} sm={6} md={4}>
        <Box display="flex" justifyContent="center">
          <Box>
            <p id="contacts"></p>
            <Typography fontWeight="bold" variant="h5">
              Contact
            </Typography>
            <Typography color="whitesmoke">+251 70 398 0584</Typography>
            <Typography color="whitesmoke">+251 91 098 0584</Typography>
            <Typography color="whitesmoke">+251 91 169 5191</Typography>
            <Typography fontWeight="bold" variant="body2" mt={2}>
              medketsolutions@gmail.com
            </Typography>
            <Typography color="whitesmoke">Addis Ababa, Ethiopia</Typography>
            <Link
              to="https://www.linkedin.com/company/medket-tech-solutions"
              target="_blank"
            >
              <LinkedIn color="info" fontSize="large" />
            </Link>
            <Link
              to="https://www.facebook.com/MedkETdigitalsolutions"
              target="_blank"
            >
              <img
                width={60}
                alt="MedkET-fecebook"
                src="https://www.logo.wine/a/logo/Facebook/Facebook-f_Logo-Blue-Logo.wine.svg"
              />
            </Link>
          </Box>
        </Box>
      </Grid>
      <p id="contact"></p>
    </Grid>
  )
}
