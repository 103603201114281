import { AccountCircle, Email } from '@mui/icons-material'
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { Form } from 'react-router-dom'

export default function ContactForm() {
  return (
    <Form>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography fontWeight="bold" variant="h5">
          Send Email
        </Typography>
        <TextField
          sx={{
            backgroundColor: 'white',
            borderRadius: '3px',
          }}
          id="input-with-icon-textfield"
          placeholder="Full Name"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        <TextField
          sx={{
            backgroundColor: 'white',
            borderRadius: '3px',
          }}
          id="input-with-icon-textfield"
          placeholder="Email Address"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        <TextField
          sx={{
            background:
              'linear-gradient(146deg, rgba(69,170,233,1) 0%, rgba(4,69,144,1) 21%, rgba(0,0,0,1) 51%, rgba(0,23,49,1) 100%)',
            borderRadius: '3px',
            border: '1px solid #1C87CA',
          }}
          inputProps={{
            style: {
              color: 'white',
            },
          }}
          id="filled-multiline-static"
          placeholder="Message"
          multiline
          rows={4}
          variant="outlined"
        />
        <Button sx={{ width: '125px' }} variant="contained">
          send email
        </Button>
      </Box>
    </Form>
  )
}
